<template>
    <div
        class="leaderboard-container"
    >
        <div class="header-top pb-2 ml-3 mr-3">
            <span class="route-title-first">{{ $t('settingsRoute.title') }}</span>
        </div>
        <div class="tab-container">
            <div v-for="tab in tabNames" :key="tab" :class="{'tab-button-active': selectedTab === tab}" @click="toggleTab(tab)">
                {{ tab }}
            </div>
        </div>
        <form
            v-show="selectedTab === $t('settingsRoute.generalTab')"
            class="form-container"
            @submit.prevent="saveProfile"
        >
            <div class="guideline">
                <span>{{ $t('settingsRoute.guideline') }}</span>
            </div>
            <div class="form-inputs-container">
                <div
                    class="form-group"
                >
                    <label>
                        {{ $t('settingsRoute.username') }} *
                    </label>
                    <input
                        v-model="form.username"
                        type="text"
                        class="form-control"
                        name="username"
                        :class="{
                            'is-valid': form.username,
                            'is-invalid': form.username === ''
                        }"
                        trim
                    >
                </div>
                <div class="passwords-container">
                    <div
                        class="form-group"
                    >
                        <label>
                            {{ $t('settingsRoute.newPassword') }} *
                        </label>
                        <input
                            v-model="form.newPassword"
                            type="password"
                            class="form-control"
                            name="New Password"
                            :class="{
                                'is-valid': form.newPassword,
                                'is-invalid': form.newPassword === ''
                            }"
                            trim
                        >
                    </div>
                    <div
                        class="form-group"
                    >
                        <label>
                            {{ $t('settingsRoute.confirmPassword') }} *
                        </label>
                        <input
                            v-model="form.confirmPassword"
                            type="password"
                            class="form-control"
                            name="Confirm Password"
                            :class="{
                                'is-valid': form.confirmPassword,
                                'is-invalid': form.confirmPassword !== form.newPassword
                            }"
                            trim
                        >
                    </div>
                </div>
            </div>
            <button
                type="submit"
                class="btn save-button"
            >
                {{ $t('settingsRoute.save') }}
            </button>
            <div class="messages-container">
                <span v-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                </span>
            </div>
            <b-form-checkbox
                v-model="notificationSubscription"
                size="lg"
                switch
                @change="onNotificationChange"
            >
                {{ $t('settingsRoute.notificationSubscription') }}
            </b-form-checkbox>
        </form>
        <div v-show="selectedTab === $t('settingsRoute.arenaTab')" class="form-container arena-form">
            <form class="arena-form-container" @submit.prevent="saveArenaSettings">
                <div
                    class="form-group"
                >
                    <label>
                        {{ $t('settingsRoute.challengerId') }} *
                    </label>
                    <input
                        v-model="arenaForm.challengerId"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-valid': arenaForm.challengerId && !hasChallengerIdBadWords,
                            'is-invalid': arenaForm.challengerId === '' || hasChallengerIdBadWords
                        }"
                        trim
                    >
                </div>
                <button
                    type="submit"
                    class="btn save-button"
                    :class="{ disabled: disableSaveChallengerIdButton }"
                    :disabled="disableSaveChallengerIdButton"
                >
                    {{ $t('settingsRoute.save') }}
                </button>
                <div class="messages-container">
                    <span v-if="arenaErrorMessage" class="error-message">
                        {{ arenaErrorMessage }}
                    </span>
                </div>
            </form>
            <b-form-checkbox
                v-model="arenaParticipation"
                size="lg"
                switch
                @change="toggleArenaParticipation"
            >
                {{ $t('settingsRoute.arenaParticipation') }}
            </b-form-checkbox>
            <b-form-checkbox
                v-model="arenaNotifications"
                size="lg"
                switch
                :disabled="!arenaParticipation"
                @change="toggleArenaNotifications"
            >
                {{ $t('settingsRoute.notificationArena') }}
            </b-form-checkbox>
        </div>
    </div>
</template>

<script>

    import playerService from '@/services/player-service'
    import badWordsUtil from '@/utils/bad-words'
    export default {

        data() {
            return {
                selectedTab: this.$t('settingsRoute.generalTab'),
                form: {
                    username: null,
                    newPassword: null,
                    confirmPassword: null
                },
                arenaForm: {
                    challengerId: null
                },
                notificationSubscription: true,
                arenaNotifications: true,
                arenaParticipation: true,
                isGeneralFormValid: false,
                isArenaFormValid: false,
                errorMap: {
                    PLAYER_USERNAME_EXISTS: this.$t('settingsRoute.usernameExists'),
                    INVALID_DATA: this.$t('settingsRoute.invalidData'),
                    CHALLENGER_ID_TOO_SHORT: this.$t('settingsRoute.challengerIdTooShort'),
                    CHALLENGER_ID_TOO_LONG: this.$t('settingsRoute.challengerIdTooLong'),
                    CHALLENGER_ID_ALREADY_IN_USE: this.$t('settingsRoute.challengerIdAlreadyInUse'),
                    CHALLENGER_ID_HAS_BAD_WORDS: this.$t('settingsRoute.challengerIdHasBadWords')
                },
                errorMessage: null,
                arenaErrorMessage: null
            }
        },
        computed: {
            tabNames() {
                return this.areChallengesEnabled ? [this.$t('settingsRoute.generalTab'), this.$t('settingsRoute.arenaTab')] : [this.$t('settingsRoute.generalTab')]
            },
            areChallengesEnabled() {
                return this.$store.getters.areChallengesEnabled
            },
            isSaveDisabled() {
                return !this.isGeneralFormValid
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            currentChallengerId() {
                return this.player.challengerId
            },
            disableSaveChallengerIdButton() {
                return [this.currentChallengerId, ''].includes(this.arenaForm.challengerId)
            },
            hasChallengerIdBadWords() {
                return badWordsUtil.hasBadWords(this.arenaForm.challengerId)
            }
        },
        watch: {
            player: {
                handler(newVal) {
                    if (newVal) {
                        this.notificationSubscription = newVal.notificationSubscription
                        this.arenaParticipation = newVal.challengesParticipation
                        this.arenaNotifications = newVal.challengesNotificationSubscription
                        this.form.username = newVal.username
                        this.arenaForm.challengerId = newVal.challengerId
                    }
                },
                immediate: true
            }
        },
        mounted() {
            if (this.$route?.query?.tab === 'arena') {
                this.selectedTab = this.$t('settingsRoute.arenaTab')
            }
        },
        methods: {
            onNotificationChange() {
                playerService.toggleMetaDataOption('NOTIFICATION_SUBSCRIPTION').catch(() => {
                    this.notificationSubscription = !this.notificationSubscription
                })
            },
            toggleArenaParticipation() {
                playerService.toggleMetaDataOption('CHALLENGE_PARTICIPATION').then(() => {
                    this.arenaNotifications = this.arenaParticipation
                    if (!this.currentChallengerId && this.arenaParticipation) {
                        this.$store.dispatch('refreshCurrentUser')
                    }
                }).catch(() => {
                    this.arenaParticipation = !this.arenaParticipation
                })
            },
            toggleArenaNotifications() {
                playerService.toggleMetaDataOption('CHALLENGE_NOTIFICATION_SUBSCRIPTION').catch(() => {
                    this.arenaNotifications = !this.arenaNotifications
                })
            },
            toggleTab(tab) {
                this.selectedTab = tab
            },
            saveProfile() {
                this.validateGeneralForm()
                if (!this.isGeneralFormValid) {
                    return
                }
                playerService.updateCredentials({ username: this.form.username, password: this.form.newPassword })
                    .then((res) => {
                        this.$store.commit('login', res.data.token)
                        this.$router.push('/home')
                    })
                    .catch(error => {
                        this.errorMessage = this.errorMap[error.response.data.code]

                        setTimeout(() => {
                            this.errorMessage = null
                        }, 5000)
                    })
            },
            saveArenaSettings() {
                this.validateArenaForm()
                if (!this.isArenaFormValid) {
                    return
                }
                playerService.updateChallengerId(this.arenaForm.challengerId).then(() => {
                    this.$store.dispatch('setChallengerId', this.arenaForm.challengerId)
                    this.displayToast()
                })
                    .catch(error => {
                        this.arenaErrorMessage = this.errorMap[error.response.data.code]

                        setTimeout(() => {
                            this.arenaErrorMessage = null
                        }, 5000)
                    })
            },
            validateGeneralForm() {
                this.isGeneralFormValid = true
                const formErrors = {
                    username: `${this.$t('settingsRoute.username')} ${this.$t('settingsRoute.isRequired')}`,
                    newPassword: `${this.$t('settingsRoute.newPassword')} ${this.$t('settingsRoute.isRequired')}`,
                    confirmPassword: `${this.$t('settingsRoute.confirmPassword')} ${this.$t('settingsRoute.isRequired')}`
                }
                for (const [key, value] of Object.entries(this.form)) {
                    if (!value) {
                        this.isGeneralFormValid = false
                        this.errorMessage = formErrors[key]
                        setTimeout(() => {
                            this.errorMessage = null
                        }, 5000)
                        break
                    }
                }
                if (this.form?.username?.length < 6 || this.form?.username?.length > 24) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.username')} ${this.$t('settingsRoute.mustBeBetween6And24Characters')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
                if (this.form?.username.includes(' ')) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.username')} ${this.$t('settingsRoute.mustNotContainSpaces')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
                if (badWordsUtil.hasBadWords(this.form?.username)) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.username')} ${this.$t('settingsRoute.mustNotContainNaughtyWords')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
                if (this.form?.newPassword?.length < 6) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.newPassword')} ${this.$t('settingsRoute.needsAtLeast6Characters')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
                if (this.form?.newPassword !== this.form?.confirmPassword) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.confirmPassword')} ${this.$t('settingsRoute.notMatch')} ${this.$t('settingsRoute.newPassword')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
                if (this.form?.newPassword?.includes(' ') || this.form?.confirmPassword?.includes(' ')) {
                    this.isGeneralFormValid = false
                    this.errorMessage = `${this.$t('settingsRoute.newPassword')} ${this.$t('settingsRoute.mustNotContainSpaces')}`
                    setTimeout(() => {
                        this.errorMessage = null
                    }, 5000)
                }
            },
            validateArenaForm() {
                this.isArenaFormValid = true
                const formErrors = {
                    challengerId: `${this.$t('settingsRoute.challengerId')} ${this.$t('settingsRoute.mustBeOnlyLettersAndNumbers')}`
                }
                const alphaNumericRegex = /^\w+$/
                if (this.arenaForm.challengerId && !alphaNumericRegex.test(this.arenaForm.challengerId)) {
                    this.isArenaFormValid = false
                    this.arenaErrorMessage = formErrors.challengerId
                    setTimeout(() => {
                        this.arenaErrorMessage = null
                    }, 5000)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.leaderboard-container {
    padding: 1rem 0 2rem;
    width: 100%;
}

.header-top {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    text-align: left;
    color: var(--main-content-text);
    border-bottom: 2px solid var(--main-content-text);
}

.route-title-first {
    font-weight: 700;
    font-size: 1.5rem;
}

.route-title-second {
    font-weight: 300;
    color: var(--main-content-text);
}

.guideline {
    color: var(--main-content-text);
    margin: 1rem 0;
}

.tab-container {
    width: 100%;
    display: flex;
    color: var(--main-content-text);
    gap: 1rem;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.tab-button-active {
    border-bottom: 2px solid var(--main-content-text);
}

.form-container {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    color: var(--main-content-text);
}

.form-inputs-container {
    display: flex;
    flex-direction: column;
}

.arena-form {
    gap: 1rem;
}

.messages-container {
    margin: 0.5rem 0;
    height: 1rem;
    font-weight: 700;
    text-align: center;
}

.messages-container .error-message {
    color: var(--form-error-color);
}

.save-button {
    margin-top: 1rem;
    margin-left: auto;
    min-width: 100px;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid transparent;
    background: var(--secondary-color);
    color: var(--white);
    transition: all 0.2s ease-in-out;
    outline: none;
    display: grid;
    place-items: center;
}

.save-button:active:enabled {
    transform: scale(0.9);
}

.save-button.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

label {
    color: var(--main-content-text);
}

@media screen and (min-width: 500px) {
    .save-button {
        width: 130px;
        height: 45px;
    }
}

@media screen and (min-width: 768px) {
    .form-container {
        width: 100%;
        max-width: 700px;
    }

    .form-inputs-container {
        margin: 1rem 0;
        gap: 1rem;
    }

    .passwords-container {
        display: flex;
        gap: 2rem;
    }

    .form-group {
        width: calc(50% - 2rem);
    }

    .save-button {
        margin: 0;
        width: 160px;
    }

    .header-top {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 992px) {
    .form-container {
        margin: 2rem auto 0 3rem;
        text-align: left;
        display: flex;
        flex-direction: column;
    }
}

</style>
